<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/messageNotice">节假日设置</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="单位" prop="companyId">
        <el-select-tree
          :props="props"
          aria-setsize="mini"
          :options="companyResult"
          v-model="queryModel.companyId"
          size="mini"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="假日名称" prop="companyId">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.holidayName"
        ></el-input>
      </el-form-item>
      <el-form-item label="假日区间" prop="timeRanges">
        <el-date-picker
          v-model="queryModel.timeRanges"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :default-time="timeRangesDefaultTime"
          size="mini"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchImportVisible = true"
        >导入</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="downloadAll"
        >导出数据</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="companyName"
        label="单位名称"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="holidayName"
        label="节假日名称"
        width="220"
      ></el-table-column>
      <el-table-column
        prop="beginDate"
        label="开始日期"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="endDate"
        label="结束日期"
        width="150"
      ></el-table-column>
      <el-table-column prop="working" label="是否需要上班" width="120">
        <template slot-scope="{row}">
          <span v-html="row.working ? '是' : '否'"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="refWeekdayN"
        label="参考上班日"
        width="120"
      ></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >修改</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <holidayInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></holidayInfo-detail>
    <!--批量导入s-->
    <el-dialog
      title="批量导入考勤时间设置"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart/personInfo/2020/10/%E8%8A%82%E5%81%87%E6%97%A5%E8%AE%BE%E7%BD%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls"
            type="primary"
            target="_blank"
            >点击下载模板</el-link
          >
        </el-form-item>
        <el-form-item label="单位">
          <el-select-tree
            size="large"
            :props="props"
            :options="companyResult"
            v-model="uploadXlsData.companyId"
            height="200"
            width="300px"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :data="uploadXlsData"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <!--批量导入E-->
  </div>
</template>
<script>
import Constant from "@/constant";
import holidayInfoDetail from "./holidayInfo-detail";
import holidayInfoApi from "@/api/base/holidayInfo";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseHolidayInfoList",
  data() {
    var self = this;

    return {
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      timeRangesDefaultTime: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      queryModel: {
        companyId: "",
        holidayName: "",
        timeRanges: "",
      },
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      batchImportVisible: false,
      batchImportFileList: [],
      uploadUrlXls: Constant.serverUrl + "/base/holidayInfo/importXls",
      uploadXlsData: {
        subFolder: "company",
        companyId: "",
      },
      headers: {
        Authorization: getToken(),
      },
      xlsLoading: false,
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  methods: {
    changePage(pageIndex) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      var startDate = "";
      var endDate = "";
      var timeRanges = self.queryModel.timeRanges + "";

      if (timeRanges != "" && timeRanges != null) {
        timeRanges = timeRanges.split(",");
        startDate = timeRanges[0];
        endDate = timeRanges[1];
      }

      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }

      if (self.queryModel.holidayName) {
        formData.append("holidayName", self.queryModel.holidayName);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }
      holidayInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight =
            window.innerHeight - self.$refs.formTable.$el.offsetTop - 45;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          holidayInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        holidayInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    beforeUpload(file, fileList) {
      //导入前判断
      var self = this;
      var companyId = self.uploadXlsData.companyId;

      if (companyId == null || companyId.length == 0) {
        self.$message({
          type: "warning",
          message: "请选择单位!",
        });
        self.xlsLoading = false;
        return false;
      } else {
        self.xlsLoading = true;
        return true;
      }
    },
    //批量导入-上传成功
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.xlsLoading = false;
      self.uploadXlsData.companyId = null;

      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    //批量导入-预览
    handleBatchImportPreview(file) {
      console.log(file.url);
    },
    //批量导入-移除
    handleBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-移除前操作
    beforeBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-文件超出个数限制时的钩子
    handleBatchImportExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    downloadAll() {
      //导出
      var self = this;
      var formData = new FormData();

      var startDate = "";
      var endDate = "";
      var timeRanges = self.queryModel.timeRanges + "";

      if (timeRanges != "" && timeRanges != null) {
        timeRanges = timeRanges.split(",");
        startDate = timeRanges[0];
        endDate = timeRanges[1];
      }

      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }

      if (self.queryModel.holidayName) {
        formData.append("holidayName", self.queryModel.holidayName);
      }

      self.loading = true;
      self.xlsLoading = true;

      holidayInfoApi.exportXls(formData).then(function (response) {
        self.loading = false;
        self.xlsLoading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null) {
            if (document.location.href.startsWith("https://")) {
              jsonData.data = jsonData.data.replace("http://", "https://");
            }

            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        }
      });
    },
  },
  mounted: function () {
    var self = this;
    this.changePage(1);
  },
  components: {
    "holidayInfo-detail": holidayInfoDetail,
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>