
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    width="800px;"
    @close="closeDialog"
    :close-on-click-modal="false"
  > 
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="单位名称" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
            size="mediumn"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="节假日名称" prop="holidayName">
          <el-input v-model="formModel.holidayName" placeholder="请输入电表名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="beginDate">
          <el-date-picker v-model="formModel.beginDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endDate">
          <el-date-picker v-model="formModel.endDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否需要上班" prop="working">
          <el-radio v-model="formModel.working" :label="true">是</el-radio>
          <el-radio v-model="formModel.working" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="参考上班日" prop="refWeekday">
          <el-select
            v-model="formModel.refWeekday"
            placeholder="请选择"
            style="width:30% "
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import holidayInfoApi from "@/api/base/holidayInfo";
import { quillEditor } from "vue-quill-editor";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        holidayName: [{ required: true, message: "不能为空", trigger: "blur" }],
        beginDate: [{ required: true, message: "不能为空", trigger: "blur" }],
        endDate: [{ required: true, message: "不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      rangeTime: "",
      options: [
        {
          value: 0,
          label: "无"
        },
        {
          value: 1,
          label: "周一"
        },
        {
          value: 2,
          label: "周二"
        },
        {
          value: 3,
          label: "周三"
        },
        {
          value: 4,
          label: "周四"
        },
        {
          value: 5,
          label: "周五"
        },
        {
          value: 6,
          label: "周六"
        },
        {
          value: 7,
          label: "星期天"
        }
      ],

      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            self.loading = true;

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return holidayInfoApi.add(self.formModel);
            } else {
              return holidayInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            self.loading = false;

            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        return holidayInfoApi.create();
      } else {
        return holidayInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (self.businessKey.length == 0) {
            self.formModel.status = false;
          }

          var working = self.formModel.working;
          if (working == null) {
            self.formModel.working = false;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>